import { useAuth } from "context/auth_context";
import { ProjectListScreen } from "screens/project-list";
import styled from "@emotion/styled";
import { ButtonNoPadding, Row } from "components/lib";
import { ReactComponent as Logo } from "./assets/tasklogo.svg";
import { Button, Dropdown, Menu } from "antd";
import { Route, Routes } from "react-router";
import { BrowserRouter as Router, Navigate } from "react-router-dom";

import { resetRoute } from "utils";
import { ProjectScreen } from "screens/project";
import { useState } from "react";
import { ProjectModal } from "screens/project-list/project-modal";
import { ProjectPopover } from "components/project-popover";
import { UserPopover } from "components/user-popover";

export const AuthenticatedApp = () => {
  // const [projectModalOpen, setProjectModalOpen] = useState(false)

  return (
    <div>
      <Container>
        <Router>
          <PageHeader />
          <Main>
            <Routes>
              <Route path={"/projects"} element={<ProjectListScreen />} />
              <Route
                path={"projects/:projectId/*"}
                element={<ProjectScreen />}
              />
              <Route path="*" element={<Navigate to="/projects" replace />} />
              {/* <Route index element={<ProjectListScreen />} /> */}
            </Routes>
          </Main>
          <ProjectModal />
        </Router>
      </Container>
      <PageFooter />
    </div>
  );
};

const PageHeader = () => {
  return (
    <Header between={true}>
      <HeaderLeft gap={2}>
        <Logo width={"8rem"} onClick={resetRoute} />
        <h2 onClick={resetRoute}>Task Management</h2>
        <ButtonNoPadding type={"link"}>
          <ProjectPopover />
        </ButtonNoPadding>
        <UserPopover />
      </HeaderLeft>
      <HeaderRight>
        <User />
      </HeaderRight>
    </Header>
  );
};

const PageFooter = () => {
  return (
    <Footer>
      {"\u00A0"}
      {"\u00A0"}
      {"\u00A0"}
      <h3>
        Made with ❤ by Samuel
      </h3>
    </Footer>
  );
};

const User = () => {
  const { logout, user } = useAuth();
  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item key={"logout"}>
            <Button onClick={logout} type={"link"}>
              Logout
            </Button>
          </Menu.Item>
        </Menu>
      }
    >
      <Button type={"link"} onClick={(e) => e.preventDefault()}>
        Hi, {user?.name}
      </Button>
    </Dropdown>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: 6rem;
  height: 95vh;
`;

const Header = styled(Row)`
  padding: 3.2rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const Footer = styled(Row)`
  height: 5vh;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const HeaderLeft = styled(Row)``;
const HeaderRight = styled.div``;
const Main = styled.main`
  display: flex;
  overflow-x: hidden;
`;
