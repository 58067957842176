import styled from "@emotion/styled"
import {  Divider, List, Popover, Typography } from "antd"
import { useProjectModal } from "screens/project-list/util"

import { useProjects } from "utils/project"
import { ButtonNoPadding } from "./lib"

export const ProjectPopover =() => {

    const {open} = useProjectModal()
    const {data:projects, refetch} = useProjects()
    //@ts-ignore
    const pinnedProjects = projects?.filter(project => project.pin)

    const content = <ContentContainer>
        <Typography.Text type={"secondary"}>Favorite</Typography.Text>
        <List>
            {
                //@ts-ignore
                pinnedProjects?.map(project => <List.Item>
                    <List.Item.Meta title={project.name} />
                </List.Item>)
            }
        </List>
        <Divider />
        <ButtonNoPadding onClick={open} type={"link"}>Create New Project</ButtonNoPadding>
    </ContentContainer>
    return <Popover onVisibleChange={() => refetch()} placement={"bottom"} content={content}>
       <span>Project</span> 

    </Popover>
}

const ContentContainer = styled.div`
    min-width: 10rem;
`