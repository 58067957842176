import styled from "@emotion/styled";
import { Button, Drawer, Form, Input } from "antd";
// import { useForm } from "antd/es/form/Form";
// import { ErrorBox } from "components/lib";
import { UserSelect } from "components/user-select";
import { useEffect } from "react";
import { useAddProject, useEditProject } from "utils/project";
import { useProjectModal, useProjectsQueryKey } from "./util";

export const ProjectModal = () => {
  const { projectModalOpen, close, editingProject, isLoading } =
    useProjectModal();

  const useMutateProject = editingProject ? useEditProject : useAddProject;

  const {
    mutateAsync,
    error,
    isLoading: mutateLoading,
  } = useMutateProject(useProjectsQueryKey());

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    mutateAsync({ ...editingProject, ...values }).then(() => {
      form.resetFields();
      close();
    });
  };

  const closeModal = () => {
    form.resetFields();
    close();
  };

  const title = editingProject ? "EditProject" : "CreateProject";

  useEffect(() => {
    form.setFieldsValue(editingProject);
  }, [editingProject, form]);

  return (
    <Drawer
      forceRender
      onClose={closeModal}
      visible={projectModalOpen}
      width={"100%"}
    >
      <Container>
        <>
          <h1>{title}</h1>
          {/* <ErrorBox error={error} /> */}
          <Form
            form={form}
            layout={"vertical"}
            style={{ width: "40rem" }}
            onFinish={onFinish}
          >
            <Form.Item
              label={"Name"}
              name={"name"}
              rules={[
                {
                  required: true,
                  message: "Please input the name of the project",
                },
              ]}
            >
              <Input placeholder={"Please input the name of the project"} />
            </Form.Item>
            <Form.Item
              label={"Department"}
              name={"organization"}
              rules={[
                {
                  required: true,
                  message: "Please input the name of the department",
                },
              ]}
            >
              <Input placeholder={"Please input the name of the department"} />
            </Form.Item>
            <Form.Item label={"Process Owner"} name={"personId"}>
           
              <UserSelect defaultOptionName={"Process Owner"} />
            </Form.Item>

            <Form.Item style={{ textAlign: "right" }}>
              <Button
                loading={mutateLoading}
                type={"primary"}
                htmlType={"submit"}
              >
                Submit
              </Button>

              {/* <Button onClick={close}>Close</Button> */}
            </Form.Item>
          </Form>
        </>
      </Container>
    </Drawer>
  );
};

const Container = styled.div`
  flex-direction: column;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
