import { useState } from "react";
import { LoginScreen } from "./login";
import { RegisterScreen } from "./register";
import { Button, Card, Divider } from "antd";
import styled from "@emotion/styled";
import logo from "../assets/tasklogo.svg";
import backgroundimg1 from "../assets/approve.svg";
import backgroundimg2 from "../assets/app.svg";
import {Helmet} from 'react-helmet'
import { ErrorBox } from "components/lib";

export const UnauthenticatedApp = () => {
  const [isRegister, setIsRegister] = useState(false);
  const [error, setError] = useState<Error | null>(null)

  return (
    <div>
      <Background />
      <Container>
        <Helmet>
          <title>Welcome</title>
        </Helmet>
  
        <Header />

        <h1>Task Management System</h1>

        <Title>{isRegister ? "Please Register" : "Please Login"}</Title>
        <ErrorBox error={error}/>
        {/* {error ? <Typography.Text type={"danger"}>{<p>Failed...Please try it again</p> }</Typography.Text> : null} */}
        <ShadowCard>
          {isRegister ? <RegisterScreen onError = {setError} /> : <LoginScreen onError = {setError}/>}
          <Divider />
          <LongButton onClick={() => setIsRegister(!isRegister)}>
            Jump to {isRegister ? "Login" : "Register"}
          </LongButton>
        </ShadowCard>
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

const ShadowCard = styled(Card)`
  width: 40rem;
  min-height: 20rem;
  padding: 3.2rem 4rem;
  border-radius: 0.3rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
  text-align: center;
`;
const Header = styled.header`
  background: url(${logo}) no-repeat center;
  padding: 5rem 0;
  background-size: 8rem;
  width: 8rem;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left bottom, right bottom;
  background-size: calc(((100vw - 40rem) / 2) - 3.2rem),
    calc(((100vw - 40rem) / 2) - 3.2rem), cover;
  background-image: url(${backgroundimg1}), url(${backgroundimg2});
`;

const Title = styled.h2`
  margin-bottom: 2.4rem;
  color: rgb(94, 108, 132);
`;

export const LongButton = styled(Button)`
  width: 100%;
`;
