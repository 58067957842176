import {Dropdown, Menu, Modal, Table, TableProps} from "antd";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import {Pin} from "components/pin";
import {useDeleteProject, useEditProject} from "utils/project";
import {ButtonNoPadding} from "components/lib";
import {useProjectModal, useProjectsQueryKey} from "./util";
import {User} from "types/user";
import {Project} from "../../types/project";

interface ListProps extends TableProps<Project> {
  users: User[];
}

export const List = ({ users, ...props }: ListProps) => {
  const { mutate } = useEditProject(useProjectsQueryKey());
  // const {projectModalOpen, open} = useProjectModal()
  const pinProject = (id: number) => (pin: boolean) => mutate({ id, pin });
  // const { startEdit } = useProjectModal();
  // const editProject = (id: number) => () => startEdit(id);
  return (
    <Table
      rowKey={'id'}
      pagination={false}
      columns={[
        {
          title: <Pin checked={true} disabled={true} />,
          render(value, project) {
            return (
              <Pin
                checked={project.pin}
                onCheckedChange={pinProject(project.id)}
              />
            );
          },
        },
        {
          title: "Name",
          sorter: (a, b) => a.name.localeCompare(b.name),
          // dataIndex:'name'
          render(value, project) {
            return (
              <Link to={`${String(project.id)}`}>{project.name}</Link>
            );
          },
        },
        {
          title: "Organization",
          dataIndex: "organization",
        },
        {
          title: "Processor",
          render(value, project) {
            return (
              <span>
                {users.find((user) => user.id === project.personId)?.name ||
                  "Unknow"
                }
              </span>
            );
          },
        },
        // {
        //   title: "Created Date",
        //   render(value, project) {
        //     return (
        //       <span>
        //         {project.created
        //           ? dayjs(project.created).format("YYYY-MM-DD")
        //           : "None"}
        //       </span>
        //     );
        //   },
        // },
        {
          render(value, project) {
            return <More project={project} />;
          },
        },
      ]}
      {...props}
    />
  );
};

const More = ({ project }: { project: Project }) => {
  const { startEdit } = useProjectModal();
  const editProject = (id: number) => () => startEdit(id);
  const { mutate: deleteProject } = useDeleteProject(useProjectsQueryKey());
  const confrimDeleteProject = (id: number) => {
    Modal.confirm({
      title: "Are you sure to delete?",
      content: "Please Click Yes to delete",
      okText: "Yes",
      onOk() {
        deleteProject({ id });
      },
    });
  };

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item onClick={editProject(project.id)} key={"edit"}>
            Edit
          </Menu.Item>
          <Menu.Item
            onClick={() => confrimDeleteProject(project.id)}
            key={"delete"}
          >
            Delete
          </Menu.Item>
        </Menu>
      }
    >
      <ButtonNoPadding type={"link"}>...</ButtonNoPadding>
    </Dropdown>
  );
};
