import { Button, List, Modal } from "antd";
import { Row, ScreenContainer } from "components/lib";
import dayjs from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useProjectInUrl } from "screens/dashboard/util";
import { Epic } from "types/epic";
import { useDeleteEpic, useEpics } from "utils/epic";
import { useTasks } from "utils/task";
import { CreateEpic } from "./create-epic";
import { useEpicSearchParams, useEpicsQueryKey } from "./util";

export const EpicScreen = () => {
    const { data: currentProject } = useProjectInUrl();
    const { data: epics } = useEpics(useEpicSearchParams());
    const { data: tasks } = useTasks({ projectId: currentProject?.id });
    const { mutate: deleteEpic } = useDeleteEpic(useEpicsQueryKey());
    const [epicCreateOpen, setEpicCreateOpen] = useState(false);
  
    const confirmDeleteEpic = (epic: Epic) => {
      Modal.confirm({
        title: `Confirm to delete the group：${epic.name}`,
        content: "Click Yes to delete",
        okText: "Yes",
        onOk() {
          deleteEpic({ id: epic.id });
        },
      });
    };
  
    return (
      <ScreenContainer>
        <Row between={true}>
          <h1>{currentProject?.name}Group</h1>
          <Button onClick={() => setEpicCreateOpen(true)} type={"link"}>
            Create Group
          </Button>
        </Row>
        <List
          style={{ overflow: "scroll" }}
          dataSource={epics}
          itemLayout={"vertical"}
          renderItem={(epic) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <Row between={true}>
                    <span>{epic.name}</span>
                    <Button onClick={() => confirmDeleteEpic(epic)} type={"link"}>
                      Delete
                    </Button>
                  </Row>
                }
                // description={
                //   <div>
                //     <div>Start:{dayjs(epic.start).format("YYYY-MM-DD")}</div>
                //     <div>End:{dayjs(epic.end).format("YYYY-MM-DD")}</div>
                //   </div>
                // }
              />
              <div>
                {tasks
                  ?.filter((task) => task.epicId === epic.id)
                  .map((task) => (
                    <Link
                      to={`/projects/${currentProject?.id}/dashboard?editingTaskId=${task.id}`}
                      key={task.id}
                    >
                    { task.name } {'\u00A0'} {'\u00A0'}   
                    </Link>
                  ))}
              </div>
            </List.Item>
          )}
        />
        <CreateEpic
          onClose={() => setEpicCreateOpen(false)}
          visible={epicCreateOpen}
        />
      </ScreenContainer>
    );
  };