import { useHttp } from "./http";
import { QueryKey, useQuery, useMutation } from "react-query";
import {
  useAddConfig,
  useDeleteConfig,
  useEditConfig,
} from "./use-optimistic-options";
import {Project} from "../types/project";
import { cleanObject } from "utils";

export const useProjects = (param?: Partial<Project>) => {
  const client = useHttp();

  return useQuery<Project[]>(["projects", cleanObject(param)], () =>
    client("projects", { data: param })
  );

  // const {run, ...result} = useAsync<Project[]>()

  // const fetchProjects = useCallback(
  //     () => client('projects',{data: cleanObject(param || {})}),[param, client])

  // useEffect(() => {
  //     run( fetchProjects() ,{
  //         retry:fetchProjects
  //     })

  // },[param,run, fetchProjects])

  // return result
};

export const useEditProject = (queryKey: QueryKey) => {
  const client = useHttp();
  // const queryClient = useQueryClient();
  // const [searchParams] = useProjectsSearchParams();
  // const queryKey = ['projects', searchParams]

  // const queryKey = ['projects',useProjectsSearchParams()]

  return useMutation(
    (params: Partial<Project>) =>
      client(`projects/${params.id}`, {
        method: "PATCH",
        data: params,
      }),
    useEditConfig(queryKey)
    // {
    //   onSuccess: () => queryClient.invalidateQueries("projects"),
    //   async onMutate(target: Partial<Project>) {
    //     const queryKey = ["projects", searchParams];
    //     const previousItems = queryClient.getQueryData(queryKey);
    //     queryClient.setQueryData(queryKey, (old?: Project[]) => {
    //       return (
    //         old?.map((project) =>
    //           project.id === target.id ? { ...project, ...target } : project
    //         ) || []
    //       );
    //     });
    //     return {previousItems}
    //   },
    //   onError(error:TypeError, newItem: Partial<Project>, context:any){
    //      queryClient.setQueryData(queryKey, context.previousItems)
    //   }
    // }
  );
  //   const {run, ...asyncResult} = useAsync()
  //    const client = useHttp()
  //    const mutate = (params: Partial<Project>)=> {

  //     return run(client(`projects/${params.id}`,{
  //         data:params,
  //         method:'PATCH'
  //     }))

  //    }
  //    return {
  //     mutate,
  //     ...asyncResult
  //    }
};

export const useAddProject = (queryKey: QueryKey) => {
  // const {run, ...asyncResult} = useAsync()
  const client = useHttp();
  // const queryClient = useQueryClient();

  return useMutation(
    (params: Partial<Project>) =>
      client(`projects`, {
        data: params,
        method: "POST",
      }),
    useAddConfig(queryKey)
  );

  // const mutate = (params: Partial<Project>)=> {

  //  return run(client(`projects/${params.id}`,{
  //      data:params,
  //      method:'POST'
  //  }))

  // }
  // return {
  //  mutate,
  //  ...asyncResult
  // }
};

export const useDeleteProject = (queryKey: QueryKey) => {
  // const {run, ...asyncResult} = useAsync()
  const client = useHttp();

  return useMutation(
    ({ id }: { id: number }) =>
      client(`projects/${id}`, {
        method: "DELETE",
      }),
    useDeleteConfig(queryKey)
  );
};

export const useProject = (id?: number) => {
  const client = useHttp();
  return useQuery(["project", { id }], () => client(`projects/${id}`), {
    enabled: Boolean(id),
  });
};

