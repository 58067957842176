
import { SearchPanel } from "./search-panel"
import { List } from "./list"
import { useDebounce } from "utils"
import styled from "@emotion/styled"
import { Button, } from "antd"
import { useProjects } from "utils/project"
import { useUsers } from "utils/user"
import {Helmet} from 'react-helmet'
import { useProjectModal, useProjectsSearchParams } from "./util"
import {  ErrorBox, Row, ScreenContainer } from "components/lib"


export const ProjectListScreen = () => {


    // const [param, setParam] = useState({
    //     name: "",
    //     personId: "",
    // })

    // const [list, setList] = useState([])

    // const client = useHttp()

    // const [users, setUsers] = useState([])

    // const [isLoading, setIsLoading] = useState(false)

    // const [error, setError] = useState<null | Error>(null)

    // const [param, setParam] = useUrlQueryParam(['name','personId'])

    // const projectsParam = {...param, personId: Number(param.personId) || undefined}

    const [param, setParam] = useProjectsSearchParams()

    // const debouncedParam = useDebounce(projectsParam, 200)

    const {isLoading, error, data:list} = useProjects(useDebounce(param, 200))

    const {data: users} = useUsers()

    const {open} = useProjectModal()

    // const {run, isLoading, error, data: list} = useAsync<Project[]>()

    // useEffect(() => {
    //     run( client('projects',{data: cleanObject(debouncedParam)}))
    //     // setIsLoading(true)
    //     // client('projects',{data: cleanObject(debouncedParam)}).then(setList)
    //     // .catch(error => {
    //     //     setList([])
    //     //     setError(error)})
    //     // .finally(()=>setIsLoading(false))
    // },[debouncedParam])

    // useMount(() => {
    //     client('users').then(setUsers)
        
    // })


    return (
    <ScreenContainer>

        <Helmet>
          <title>Project List</title>
        </Helmet>

        <Row between={true} marginBottom={2}> 
            <h1>Project List</h1>
            <Button onClick={open} type={"link"}>Create New Project</Button>
        </Row>
  
       
        <SearchPanel 
            users={users || []}
            param={param} 
            setParam={setParam}
            
            />
        <ErrorBox error={error}/>
        {/* {error? <Typography.Text type={"danger"}>{error.message}</Typography.Text>: null} */}
        <List 
           
             loading={isLoading}
             dataSource={list || []} 
             users={users || []}
           
        />
    </ScreenContainer>
    )
}

// const Container = styled.div`
//     padding: 3.2rem;
   
// `