import { AuthenticatedApp } from 'authenticated-app';
import { ErrorBoundary } from 'components/error-boundary';
import { FullPageErrorFallback } from 'components/lib';
import { useAuth } from 'context/auth_context';
import React from 'react';
import { UnauthenticatedApp } from 'unauthenticated-app';
import "./App.css"


function App() {

  const {user} = useAuth()
  return (
    <div className="App">
      <ErrorBoundary fallbackRender={FullPageErrorFallback}>
        {user? <AuthenticatedApp/> : <UnauthenticatedApp />}
      </ErrorBoundary>
      
    </div>
  );
}

export default App;
